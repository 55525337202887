<template>
	<article
		:class="{'timeline__post--admin' : post.is_admin_post}"
		class="timeline__post">
		<header class="timeline__post__header">
			<div class="timeline__post__left">
				<router-link :to="{ name: 'usersprofile', params: { id: post.post_author }}" class="timeline__post__author-link"></router-link>
				<user-badge :rank="post.author_rank.tier_name" :avatar="post.avatar" :color="post.author_rank.tier_colour"/>
				<div>
					<p
						class="timeline__post__author ">
						<template v-if="post.is_admin_post">
							Admin Team -
						</template>
						<span
							v-if="isEvent"
							v-html="post.post_title"/>
						<template v-else-if="isAuthor"> You</template>
						<template v-else> {{ post.author_name }}</template>
					</p>
					<time
						v-if="!isEvent"
						class="timeline__post__time">{{ post.author_role }}</time>
					<time
						v-else-if="isEvent"
						class="timeline__post__time timeline__post__time--event">{{ post.start_time }}</time>

					<span class="timeline__post__time--humantime">{{postTime}}</span>
				</div>
			</div>

			<div
				v-if="!isEvent"
				class="timeline__post__topright">
				<span
					v-if="post.pinned"
					class="timeline__post__topright__pinned">
						<svg v-tooltip="'Pinned Post'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColour" d="M6.166 16.943l1.4 1.4-4.622 4.657h-2.944l6.166-6.057zm11.768-6.012c2.322-2.322 4.482-.457 6.066-1.931l-8-8c-1.474 1.584.142 3.494-2.18 5.817-3.016 3.016-4.861-.625-10.228 4.742l9.6 9.6c5.367-5.367 1.725-7.211 4.742-10.228z"/></svg>
				</span>
				<span
					v-if="postStatus == 'pending'"
					class="timeline__post__topright__review">
					<svg
						v-tooltip="'Under Review'"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						class="w-3 h-3">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
					</svg>
				</span>
				<svg
					v-if="post.is_admin_post"
					class="timeline__post__alert"
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M11.3808 13.235C12.9334 13.0512 14.459 12.6847 15.9258 12.1433C14.6836 10.7673 13.9972 8.97875 13.9999 7.125V6.54167V6.5C13.9999 5.17392 13.4732 3.90215 12.5355 2.96447C11.5978 2.02678 10.326 1.5 8.99994 1.5C7.67386 1.5 6.40209 2.02678 5.4644 2.96447C4.52672 3.90215 3.99994 5.17392 3.99994 6.5V7.125C4.00245 8.97886 3.31576 10.7675 2.07327 12.1433C3.51744 12.6767 5.03994 13.0475 6.6191 13.235M11.3808 13.235C9.79909 13.4226 8.20078 13.4226 6.6191 13.235M11.3808 13.235C11.5009 13.6099 11.5307 14.0078 11.4679 14.3964C11.4051 14.785 11.2515 15.1533 11.0194 15.4713C10.7874 15.7893 10.4835 16.048 10.1326 16.2263C9.78167 16.4047 9.39359 16.4976 8.99994 16.4976C8.60629 16.4976 8.21821 16.4047 7.86728 16.2263C7.51635 16.048 7.2125 15.7893 6.98046 15.4713C6.74841 15.1533 6.59474 14.785 6.53195 14.3964C6.46916 14.0078 6.49902 13.6099 6.6191 13.235M1.60327 5.25C1.83825 3.85414 2.46477 2.5537 3.40994 1.5M14.5899 1.5C15.5351 2.5537 16.1616 3.85414 16.3966 5.25"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"/>
				</svg>

				<div
					v-if="(isAdmin || isAuthor) && !simple"
					class="timeline__post__menu"
					:class="{'timeline__post__menu--open': showingMenu}"
					>
					<svg
					@click="toggleMenu"
						fill="currentColor"
						height="25"
						width="25"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						viewBox="0 0 32.055 32.055"
						xml:space="preserve">
						<path
							d="M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967
							C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967
							s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967
							c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z"/>
					</svg>
					<transition name="slide">
						<div
							v-if="showingMenu"
							class="timeline__post__menu__dropdown">
							<ul>
								<li
									v-if="isAdmin && postStatus !== 'publish'"
									class="timeline__post__menu__dropdown__action"
									@click="approvePost(post.ID)">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
											<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
										</svg>

										Approve this post
									
									</li>
								<li
									class="timeline__post__menu__dropdown__action"
									@click="showDeletePost(post.ID)">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
										<path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
									</svg> Delete this post</li>
									
									<li
										v-if="isAdmin && !post.pinned"
										class="timeline__post__menu__dropdown__action timeline__post__menu__dropdown__action--pin-actions"
										:class="{'timeline__post__menu__dropdown__action--pin-actions--open': showPinExpiry}"
										style="padding: 0"
										>
										<div class="" @click.prevent="showPinExpiry = !showPinExpiry" style="padding: 1rem">
											<svg xmlns="http://www.w3.org/2000/svg" class="size-4" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="M6.166 16.943l1.4 1.4-4.622 4.657h-2.944l6.166-6.057zm11.768-6.012c2.322-2.322 4.482-.457 6.066-1.931l-8-8c-1.474 1.584.142 3.494-2.18 5.817-3.016 3.016-4.861-.625-10.228 4.742l9.6 9.6c5.367-5.367 1.725-7.211 4.742-10.228z"/></svg>
											Pin this post
										</div>
									
									<transition name="slide">
									<div v-if="showPinExpiry" class="timeline__post__menu__dropdown__action__expiry">
										<label>Unpin this post on:</label>
										<div class="timeline__post__menu__dropdown__action__expiry__inputs">
											<input :name="'pin_expiry_date_' + post.ID" :id="'pin_expiry_date_' + post.ID" required="required" type="datetime-local" v-model="pinExpiry" class="form__control form__control--date-time"  style="margin-top: 0;"/>

											<button @click="togglePostPin(post.ID)" class="icon-button">
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-7">
													<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
												</svg>
											</button>
										</div>
									</div>
									</transition>


								</li>

								<li
									v-if="isAdmin && post.pinned"
									class="timeline__post__menu__dropdown__action"
									@click="togglePostPin(post.ID)">
									<svg xmlns="http://www.w3.org/2000/svg" class="size-4" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="M6.166 16.943l1.4 1.4-4.622 4.657h-2.944l6.166-6.057zm11.768-6.012c2.322-2.322 4.482-.457 6.066-1.931l-8-8c-1.474 1.584.142 3.494-2.18 5.817-3.016 3.016-4.861-.625-10.228 4.742l9.6 9.6c5.367-5.367 1.725-7.211 4.742-10.228z"/></svg>
										Unpin this post
								

								</li>
								

								<li
									class="timeline__post__menu__dropdown__action"
									@click="toggleComments(post.ID)">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
										<path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
									</svg>


									{{ postObject.comment_status === 'open' ? 'Disable comments' : 'Enable comments' }}</li>

								<li
									v-if="isAdmin"
									:class="{'timeline__post__menu__dropdown__action--disabled': !canDispatch}"
									class="timeline__post__menu__dropdown__action"
									@click="sendNotification(post.ID)">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
										<path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
									</svg>

									<div>Send Notifications <br />
										<small style="font-size: 0.7rem; margin: 0.25rem 0; display:block;">
											<span v-if="notificationCount ">( Sent {{ notificationCount || 0 }} Times )</span>
											<span v-else>( Never sent )</span>
										</small>
									</div>

								</li>
							</ul>
						</div>
					</transition>
				</div>
			</div>

		</header>

		<div
			v-if="!isEvent"
			class="timeline__post__body">
			<p style="white-space: pre-wrap;" v-html="content" v-if="content !== 'null'"></p>
		</div>
		<div
			v-else-if="isEvent"
			class="timeline__post__body">
			<p v-if="content !== 'null'">{{ post.post_excerpt }}</p>
		</div>

		<div v-if="post.video">
			<video :src="post.video.url" preload="metadata" class="timeline__post__video" controls="true" />
		</div>
		<div v-else>
			<div
				v-if="post.gallery"
				class="timeline__post__gallery">

					<a :href="post.gallery[activeImage]" v-if="post.gallery.length < 2"><img
						:src="post.gallery[activeImage]"
						class="timeline__post__body__image"
						@click="imageIndex=activeImage" >
					</a>
					<div v-else>
						<img
						:src="post.gallery[activeImage]"
						class="timeline__post__body__image"
						@click="imageIndex=activeImage" />
					<Slideshow
						:index="imageIndex"
						:images="post.gallery"
						@closeLightbox="closeLb"
						@openLightbox="openLb"/>
					</div>
			</div>

			<div
				v-if="post.gallery && post.gallery.length > 1"
				class="timeline__post__gallery--multiple">
				<div class="insights__post__gallery">
					<img
						v-for="(image, key) in post.gallery"
						v-if="key <= (maxImagesPerGallery - 1)"
						:key="key"
						:src="image"
						:class="{'insights__post__gallery__image--active': key === activeImage}"
						class="insights__post__gallery__image"
						@click.prevent="activeImage = key"
					>
					<div
						v-if="post.gallery.length > maxImagesPerGallery"
						class="insights__post__gallery__more"
						@click.prevent="imageIndex = 0"
					>
						+{{ post.gallery.length - maxImagesPerGallery }}
					</div>

				</div>

			</div>
		</div>

		<div v-if="isPoll" class="timeline__poll">
			<div v-if="!showPollResults">
				<button
					v-for="(choice, idx) in post.choices"
					:key="idx + '-choice-'+post.ID"
					type="button"
					class="timeline__poll__choice"
					@click="makeChoice(idx)">
					{{ choice.choice_label }}
				</button>
			</div>

			<div v-if="showPollResults">
				<div
					v-for="(choice, idx) in post.choices"
					:key="idx + '-result-'+post.ID"
					class="">
						<poll-result-bar-component :choice="choice" :total="totalVotes" />
				</div>

				<p v-if="post.has_expired" style="opacity:0.75; margin-left: 3px; font-style:italic; margin-top: 2px;">This poll has now ended</p>
			</div>

		</div>

		<div
			v-if="post.attachment"
			class="timeline__post__attachment">
			<a
				:href="post.attachment.url"
				@click="attachmentClick"
				class="timeline__post__attachment__button button"
				target="_blank">View Attachment</a>
		</div>

		<div
			v-if="isEvent"
			class="timeline__post__body">
			<a
				:href="post.guid"
				class="timeline__post__link"
				@click="eventClick"
				target="_blank">
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M11.0908 6.96399C11.6865 7.2484 12.2052 7.6716 12.6034 8.19805C13.0016 8.7245 13.2677 9.33882 13.3792 9.98941C13.4908 10.64 13.4446 11.3079 13.2445 11.9369C13.0444 12.5659 12.6962 13.1377 12.2293 13.6043L8.10433 17.7293C7.33074 18.5029 6.28152 18.9375 5.18749 18.9375C4.09347 18.9375 3.04425 18.5029 2.27066 17.7293C1.49707 16.9557 1.06247 15.9065 1.06247 14.8125C1.06247 13.7185 1.49707 12.6693 2.27066 11.8957L3.88124 10.2851M16.1187 9.71491L17.7293 8.10433C18.5029 7.33074 18.9375 6.28152 18.9375 5.18749C18.9375 4.09347 18.5029 3.04425 17.7293 2.27066C16.9557 1.49707 15.9065 1.06247 14.8125 1.06247C13.7185 1.06247 12.6693 1.49707 11.8957 2.27066L7.77066 6.39566C7.30375 6.86225 6.95562 7.43407 6.75553 8.0631C6.55544 8.69213 6.50922 9.35999 6.62077 10.0106C6.73231 10.6612 6.99836 11.2755 7.39656 11.8019C7.79476 12.3284 8.31349 12.7516 8.90916 13.036"
						stroke="currentcolor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"/>
				</svg>
				View Details
			</a>
		</div>

		<div
			v-if="post.link && post.link !== null"
			class="timeline__post__body"
		>
		
			<a
				v-if="post.link"
				:href="post.link"
				class="timeline__post__link"
				target="_blank">
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M11.0908 6.96399C11.6865 7.2484 12.2052 7.6716 12.6034 8.19805C13.0016 8.7245 13.2677 9.33882 13.3792 9.98941C13.4908 10.64 13.4446 11.3079 13.2445 11.9369C13.0444 12.5659 12.6962 13.1377 12.2293 13.6043L8.10433 17.7293C7.33074 18.5029 6.28152 18.9375 5.18749 18.9375C4.09347 18.9375 3.04425 18.5029 2.27066 17.7293C1.49707 16.9557 1.06247 15.9065 1.06247 14.8125C1.06247 13.7185 1.49707 12.6693 2.27066 11.8957L3.88124 10.2851M16.1187 9.71491L17.7293 8.10433C18.5029 7.33074 18.9375 6.28152 18.9375 5.18749C18.9375 4.09347 18.5029 3.04425 17.7293 2.27066C16.9557 1.49707 15.9065 1.06247 14.8125 1.06247C13.7185 1.06247 12.6693 1.49707 11.8957 2.27066L7.77066 6.39566C7.30375 6.86225 6.95562 7.43407 6.75553 8.0631C6.55544 8.69213 6.50922 9.35999 6.62077 10.0106C6.73231 10.6612 6.99836 11.2755 7.39656 11.8019C7.79476 12.3284 8.31349 12.7516 8.90916 13.036"
						stroke="currentcolor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"/>
				</svg>
				Visit Link
			</a>
		</div>

		<Social
			:user="user"
			:post="postObject"
			@postUnbookmarked="postUnbookmarked"
			@postBookmarked="postBookmarked"
		/>
	</article>
</template>

<script>
import Social from '@/components/TimelineComponents/TimelineArticleSocialComponent';
import Slideshow from '@/components/TimelineComponents/SlideshowComponent';
import UserBadge from '@/components/UserBadgeComponent';
import PollResultBarComponent from './PollResultBarComponent.vue';

export default {
	components: {
		Social,
		Slideshow,
		UserBadge,
		PollResultBarComponent
	},

	props: {
		post: {
			type: Object,
			required: true,
		},
		user: {
			type: Object,
			required: true,
		},

		simple: {
			typie: Boolean,
			required: false,
			default: false,
		}
	},

	data () {
		return {
			postObject: this.post,
			postStatus: this.post.post_status,
			showingMenu: false,
			likeCounter: this.post.likes,
			likedByUser: this.post.liked_by_user,
			maxImagesPerGallery: 4,
			canDispatch: true,
			imageIndex: null,
			activeImage: 0,
			notificationCount: this.post.notifications_dispatched,
			showPollResults: this.post.has_made_choice,
			totalVotes: this.post.poll_total,
			showPinExpiry: false,
			pinExpiry: null,
		};
	},

	computed: {
		isAdmin () {
			if (this.user.role === 'editor' || this.user.role === 'administrator') {
				return true;
			}

			return false;
		},

		postTime () {
			return window.moment(this.post.post_date).fromNow();
		},

		isAuthor () {
			if (this.user.id == this.post.post_author) {
				return true;
			}

			return false;
		},

		isPoll () {
			return this.post.timeline_type === 'poll';
		},

		isEvent () {
			if (this.post.post_type === 'timeline_event') {
				return true;
			}
			return false;
		},

		content () {
			var regex = /(?=@)(.*?)(?= )/;
			let content = this.post.post_content;
			let string = this.post.post_content.match(regex);
			if (!string) {
				return this.post.post_content;
			}

			if (string.length < 1) {
				return this.post.post_content;
			}

			string.forEach(function (e) {
				content = content.replace(e, '<span class="tagged">' + e + '</span>');
			});

			return content;
		},
	},

	watch: {
    // 	$route(to, from) {
	// 		if(from.hash === '#lightbox') {
	// 			// Close Post
	// 			this.closeLb()
	// 		}
    // 	}
  	},

	created() {

		window.addEventListener("hashchange", (event) => {
			if(event.oldURL.includes('#menuOpen')) {
				this.showingMenu = false;
			}

			if(event.oldURL.includes('#lightbox')) {
				this.closeLb();
			}
		});
	},

	methods: {

		toggleMenu() {
			this.showingMenu = !this.showingMenu

			if(this.showingMenu) {
				history.pushState(true, '', '#menuOpen')
			} else {
				this.showPinExpiry = false;
				history.replaceState(null, null, ' ');
			}
		},

		closeLb() {
			this.imageIndex = null
			this.$emit('closeLightbox');
			
		},

		openLb() {
			this.$emit('openLightbox');
		},

		showDeletePost (postId) {
			this.toggleMenu();
			this.$emit('showDeletePost', postId);
		},


		makeChoice (choice) {
			if(!this.post.choices[choice].selected_by) {
				this.post.choices[choice].selected_by = [this.user.id];
			} else {
				this.post.choices[choice].selected_by.push(this.user.id);
			}
			this.totalVotes = (this.totalVotes + 1);
			this.showPollResults = true;
			window.axios.post('wp-json/kxi/v1/timeline/poll/' + this.post.ID, {choiceKey: choice}).then(res => {
				this.showPollResults = true;
			}).catch(err => {
				console.log(err);
			});
			// Show results
		},

		togglePostPin (postId) {
			this.toggleMenu();

			window.axios.put('wp-json/kxi/v1/timeline/pin/' + postId, {pin_expiry: this.pinExpiry}).then(res => {
				this.post.pinned = !this.post.pinned;
			}).catch(err => {
				console.log(err);
			});
		},

		sendNotification (postId) {
			if (!this.canDispatch) {
				return;
			}

			this.toggleMenu();

			this.notificationCount++;
			this.$emit('notificationDispatched', postId);
			this.canDispatch = false;
			window.axios.post('wp-json/kxi/v1/timeline/dispatch-notification/' + postId);
		},

		approvePost (postId) {
			this.toggleMenu();

			window.axios.put('wp-json/kxi/v1/timeline/approve/' + postId).then(res => {
				this.postStatus = 'publish';
				this.$emit('postApproved', postId);
			}).catch(() => {});
		},

		toggleComments (postId) {
			this.toggleMenu();

			window.axios.put('wp-json/kxi/v1/timeline/toggle-comments/' + postId).then(res => {
				this.postObject.comment_status = this.postObject.comment_status === 'open' ? 'closed' : 'open';
				this.$emit('commentsToggled', postId);
			}).catch(() => {});
		},

		eventClick () {
			gtag('event', 'click', {'event_category': 'timeline', 'event_label': 'Event Click:' + this.post.post_title});
		},

		attachmentClick () {
			gtag('event', 'click', {'event_category': 'timeline', 'event_label': 'Attachment Click:' + this.post.post_title});
		},

		postUnbookmarked (postId) {
			this.$emit('postUnbookmarked', postId);
		},

		postBookmarked (postId) {
			this.$emit('postBookmarked', postId);
		},
	},
};
</script>
